import clsx from 'clsx'
import Link from 'next/link'
import React, { SVGProps } from 'react'

const buttonSizes = {
	xs: 'px-2.5 py-1.5 text-xs leading-3',
	sm: 'px-3 py-3 text-sm leading-4',
	md: 'px-4 py-3 text-base leading-6',
	lg: 'px-5 py-3 text-md leading-8',
	xl: 'px-6 py-3 text-xl leading-10',
}
const sizes = {
	xs: 'w-4 h-4',
	sm: 'w-5 h-5',
	md: 'w-5 h-5',
	lg: 'w-6 h-6',
	xl: 'w-6 h-6',
}

// Refactor? Tailwind doesn't recommend
const buttonBase = {
	base: 'focus:ring-gray-500',
	blue: 'focus:ring-blue-500',
	blueGray: 'focus:ring-blueGray-500',
	emerald: 'focus:ring-emerald-500',
	fuchsia: 'focus:ring-fuchsia-500',
	orange: 'focus:ring-orange-500',
	pink: 'focus:ring-pink-500',
	primary: 'focus:ring-primary-500',
	silver: 'focus:ring-silver-500',
	teal: 'focus:ring-teal-500',
	violet: 'focus:ring-violet-500',
}
const buttonThemes = {
	outline: {
		base: 'border border-gray-500',
		blue: 'border border-blue-500',
		blueGray: 'border border-blueGray-500',
		emerald: 'border border-emerald-500',
		fuchsia: 'border border-fuchsia-500',
		green: 'border border-green-500',
		orange: 'border border-orange-500',
		pink: 'border border-pink-500',
		primary: 'border border-primary-500',
		red: 'border border-red-500',
		silver: 'border border-silver-500',
		teal: 'border border-teal-500',
		violet: 'border border-violet-500',
		yellow: 'border border-yellow-500',
	},
	primary: {
		base: 'bg-gray-500 hover:bg-gray-700 dark:bg-gray-600 dark:hover:bg-gray-400',
		blue: 'bg-blue-500 hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-400',
		blueGray: 'bg-blueGray-500 hover:bg-blueGray-700 dark:bg-blueGray-600 dark:hover:bg-blueGray-400',
		emerald: 'bg-emerald-500 hover:bg-emerald-700 dark:bg-emerald-600 dark:hover:bg-emerald-400',
		fuchsia: 'bg-fuchsia-500 hover:bg-fuchsia-700  dark:bg-fuchsia-600 dark:hover:bg-fuchsia-400',
		orange: 'bg-orange-500 hover:bg-orange-700 dark:bg-orange-600 dark:hover:bg-orange-400 ',
		pink: 'bg-pink-500 hover:bg-pink-700 dark:bg-pink-600 dark:hover:bg-pink-400',
		primary: 'bg-primary-500 hover:bg-primary-700 dark:bg-primary-700 dark:hover:bg-primary-600',
		silver: 'bg-silver-500 hover:bg-silver-700 dark:bg-silver-600 dark:hover:bg-silver-400',
		teal: 'bg-teal-500 hover:bg-teal-700 dark:bg-teal-600 dark:hover:bg-teal-400',
		violet: 'bg-violet-500 hover:bg-violet-700 dark:bg-violet-600 dark:hover:bg-violet-400',
	},
	secondary: {
		base: 'bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-gray-200',
		blue: 'bg-blue-50 hover:bg-blue-100 dark:bg-blue-800 dark:hover:bg-blue-700 dark:text-blue-200',
		blueGray:
			'bg-blueGray-50 hover:bg-blueGray-100 dark:bg-blueGray-800 dark:hover:bg-blueGray-700 dark:text-blueGray-200',
		emerald: 'bg-emerald-50 hover:bg-emerald-100  dark:bg-emerald-800 dark:hover:bg-emerald-700 dark:text-emerald-200',
		green: 'bg-green-50 hover:bg-green-100  dark:bg-green-800 dark:hover:bg-green-700 dark:text-green-200',
		fuchsia: 'bg-fuchsia-50 hover:bg-fuchsia-100  dark:bg-fuchsia-800 dark:hover:bg-fuchsia-700  dark:text-fuchsia-200',
		orange: 'bg-orange-50 hover:bg-orange-100 dark:bg-orange-800 dark:hover:bg-orange-700 dark:text-orange-200',
		pink: 'bg-pink-50 hover:bg-pink-100 dark:bg-pink-800 dark:hover:bg-pink-700 dark:text-pink-200',
		primary: 'bg-primary-50 hover:bg-primary-100 dark:bg-primary-800 dark:hover:bg-primary-700 dark:text-primary-200',
		red: 'bg-red-50 hover:bg-red-100  dark:bg-red-800 dark:hover:bg-red-700 dark:text-red-200',
		silver: 'bg-silver-50 hover:bg-silver-100 dark:bg-silver-800 dark:hover:bg-silver-700 dark:text-silver-200',
		teal: 'bg-teal-50 hover:bg-teal-100 dark:bg-teal-800 dark:hover:bg-teal-700  dark:text-teal-200',
		violet: 'bg-violet-50 hover:bg-violet-100 dark:bg-violet-800 dark:hover:bg-violet-700 dark:text-violet-200',
		yellow: 'bg-yellow-50 hover:bg-yellow-100  dark:bg-yellow-800 dark:hover:bg-yellow-700 dark:text-yellow-200',
	},
}

const buttonRadius = {
	sm: 'rounded',
	md: 'rounded-md',
	lg: 'rounded-lg',
	full: 'rounded-full',
}
export type ButtonProps = {
	key?: string | number
	href?: string
	/**
	 * Optional classes
	 */
	className?: string
	/**
	 * What background color to use
	 */
	color?: string
	/**
	 * Icon component
	 */
	Icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element

	iconPosition?: 'before' | 'after' | string

	/**
	 * Button contents
	 */
	label: string
	/**
	 * Adjusts border radius of button
	 */
	rounded?: 'sm' | 'md' | 'lg' | 'full' | string

	/**
	 * How large should the button be?
	 */
	size?: string
	type?: 'submit' | 'button'

	/**
	 * Button style
	 */
	variant?: 'primary' | 'secondary' | 'outline'
	/**
	 * Optional click handler
	 */
	onClick?: () => void

	disabled?: boolean
}

/**
 * Primary UI component for user interaction
 */
export const Button = ({
	className,
	color = 'primary',
	disabled,
	href = null,
	label,
	rounded = 'md',
	size = 'md',
	type = 'button',
	variant = 'primary',
	Icon,
	onClick = null,
	...props
}: ButtonProps) => {
	const button = (
		<button
			type={type}
			disabled={disabled}
			className={clsx(
				`shadow-sm text-center focus:outline-none focus:ring-2 focus:ring-offset-2 font-medium tracking-normal inline-flex items-center justify-center transition-all disabled:bg-gray-100 disabled:text-gray-500 disabled:opacity-90`,
				buttonBase[color],
				buttonSizes[size],
				variant ? buttonThemes[variant][color] : '',
				buttonRadius[rounded],
				variant === 'primary' ? 'text-white dark:text-gray-50' : `text-${color}-700 dark:text-${color}-50`,
				className
			)}
			onClick={onClick}
			{...props}>
			<span className="sr-only">{label}</span>
			{label}
			{Icon && <Icon className={`${sizes[size]} ml-2`} aria-hidden="true" />}
		</button>
	)
	return href ? (
		<Link href={href} passHref>
			{button}
		</Link>
	) : (
		button
	)
}
