var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2b3c51441bffe5caeb3939d384f3b3bbd7030cdb"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const environment = process.env.VERCEL_ENV || process.env.NODE_ENV

Sentry.init({
	environment: environment === 'production' ? 'production' : 'development',
	dsn: SENTRY_DSN,
	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: 1.0,
	replaysSessionSampleRate: 0.1,
	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	integrations: [new Sentry.Replay()],
	// beforeSend(event, hint) {
	// 	console.log(hint)
	// 	// Check if it is an exception, and if so, show the report dialog
	// 	if (event.exception) {
	// 		Sentry.showReportDialog({
	// 			eventId: event.event_id,
	// 		})
	// 	}
	// 	return event
	// },
	// ...
	// Note: if you want to override the automatic release value, do not set a
	// `release` value here - use the environment variable `SENTRY_RELEASE`, so
	// that it will also get attached to your source maps
	release: process.env.SENTRY_RELEASE,
})
