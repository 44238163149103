import ProgressBar from '@badrap/bar-of-progress'
import { ClerkProvider, RedirectToSignIn, SignedIn, SignedOut } from '@clerk/nextjs'
import { CreateModal, IncompleteModal } from '@config/modals'
import { ColorScheme, ColorSchemeProvider, createEmotionCache, MantineProvider } from '@mantine/core'
import { useColorScheme } from '@mantine/hooks'
import { ModalsProvider } from '@mantine/modals'
import { NotificationsProvider } from '@mantine/notifications'
import { SpotlightAction, SpotlightProvider } from '@mantine/spotlight'
import { AppProps } from 'next/app'
import Head from 'next/head'
import router, { useRouter } from 'next/router'
import { Fragment, useState } from 'react'
import { SWRConfig } from 'swr'

import '../styles/globals.css'

const appendCache = createEmotionCache({ key: 'mantine', prepend: true })

import type { NextPage } from 'next'
interface layoutProps {
	Layout: () => JSX.Element
	title?: string
}

export type NextPageWithLayout = NextPage & {
	layoutProps?: layoutProps
}

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout
}

const progress = new ProgressBar({
	size: 4,
	color: '#059669',
	className: 'bar-of-progress',
	delay: 100,
})
// this fixes safari jumping to the bottom of the page
// when closing the search modal using the `esc` key
if (typeof window !== 'undefined') {
	progress.start()
	progress.finish()
}

router.events.on('routeChangeStart', () => progress.start())
router.events.on('routeChangeComplete', () => progress.finish())
router.events.on('routeChangeError', () => progress.finish())

const actions: SpotlightAction[] = [
	{
		title: 'Home',
		description: 'Get to home page',
		onTrigger: () => console.log('Home'),
	},
	{
		title: 'Purchase Orders',
		description: 'View Purchase Orders',
		onTrigger: () => router.push('/app/purchase-orders'),
	},
	{
		title: 'Machines',
		description: 'View Machine Status',
		onTrigger: () => router.push('/app/machines'),
	},
]

const publicPages = ['/sign-in/[[...index]]', '/sign-up/[[...index]]']

export default function App(props) {
	const { pathname } = useRouter()

	const isPublicPage = publicPages.includes(pathname)

	const preferredColorScheme = useColorScheme()
	const [colorScheme, setColorScheme] = useState<ColorScheme>(preferredColorScheme)
	const toggleColorScheme = (value?: ColorScheme) =>
		setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'))

	const { Component, pageProps } = props
	const Layout = Component.layoutProps?.Layout || Fragment
	const meta = Component.layoutProps?.title
		? `${Component.layoutProps.title} | Element Plastics MFG`
		: 'Element Plastics MFG'

	return (
		<>
			<Head>
				<title>{`${meta}`}</title>
				<meta
					name="viewport"
					content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
				/>
			</Head>
			<ClerkProvider {...pageProps} supportEmail={process.env.NEXT_PUBLIC_CLERK_SUPPORT_EMAIL}>
				<ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
					<MantineProvider
						emotionCache={appendCache}
						theme={{
							colorScheme,
							fontFamily: 'TTNormsPro',
							headings: { fontFamily: 'TTNormsPro' },
							dateFormat: 'MM/DD/YYYY',
							components: {
								Modal: {
									styles: {
										wrapper: {
											'&:focus': {
												outline: 'none',
											},
										},
										header: {
											fontWeight: 400,
											fontSize: '1.25rem',
										},
									},
								},
							},
						}}>
						{isPublicPage ? (
							<Layout {...Component.layoutProps}>
								<Component {...pageProps}></Component>
							</Layout>
						) : (
							<>
								<SignedIn>
									<SWRConfig
										value={{
											fetcher: (resource, init) => fetch(resource, init).then((res) => res.json()),
											onError: (error, key) => {
												if (error) {
													console.log(error, key)
													// send Sentry the error
												}
											},
										}}>
										<ModalsProvider
											labels={{ confirm: 'Submit', cancel: 'Cancel' }}
											modals={{ create: CreateModal, incomplete: IncompleteModal }}
											modalProps={{
												overlayBlur: 5,
												lockScroll: true,
											}}>
											<NotificationsProvider position="top-right">
												<SpotlightProvider
													searchPlaceholder="Search..."
													nothingFoundMessage="Nothing found..."
													actions={actions}
													shortcut={'mod + K'}>
													<Layout {...Component.layoutProps}>
														<Component {...pageProps}></Component>
													</Layout>
												</SpotlightProvider>
											</NotificationsProvider>
										</ModalsProvider>
									</SWRConfig>
								</SignedIn>
								<SignedOut>
									<RedirectToSignIn />
								</SignedOut>
							</>
						)}
					</MantineProvider>
				</ColorSchemeProvider>
			</ClerkProvider>
		</>
	)
}
