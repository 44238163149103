import { Button } from '@components/Button'
import { CheckCircleIcon } from '@heroicons/react/solid'
import { ContextModalProps, openConfirmModal } from '@mantine/modals'
import { ConfirmModalProps } from '@mantine/modals/lib/ConfirmModal'
import { showNotification } from '@mantine/notifications'
import { ReactNode } from 'react'

function handleSubmit(context, id) {
	console.log('handle submit', context, id)
	context.closeModal(id)
	showNotification({
		id: 'hello-there',
		disallowClose: true,
		onClose: () => console.log('unmounted'),
		onOpen: () => console.log('mounted'),
		autoClose: 3000,
		title: 'Successfully created organization ID ####',
		message: (
			<a href="#" className="underline">
				View Organization
			</a>
		),
		color: 'green',
		icon: <CheckCircleIcon />,
		className: 'my-notification-class',
		loading: false,
	})
}

export const IncompleteModal = ({ context, id, innerProps }: ContextModalProps<{ modalBody: ReactNode }>) => (
	<>
		{innerProps.modalBody}
		<div className="w-full mt-6">
			<Button
				variant="secondary"
				color="blue"
				label="Close"
				onClick={() => context.closeModal(id)}
				className="w-full"
			/>
		</div>
	</>
)

export const CreateModal = ({ context, id, innerProps }: ContextModalProps<{ modalBody: ReactNode }>) => (
	<>
		{innerProps.modalBody}
		<div className="flex justify-between w-full bg-gray-100 dark:bg-gray-800">
			<Button label="Cancel" onClick={() => context.closeModal(id)} />
			<Button color="primary" variant="primary" label="Submit" onClick={() => handleSubmit(context, id)} />
		</div>
	</>
)

export const openDeleteModal = (
	title: string,
	{
		children,
		labels,
		onCancel = () => console.log('Cancel'),
		onConfirm = () => console.log('Confirmed'),
	}: ConfirmModalProps
) =>
	openConfirmModal({
		title: <strong className="text-xl font-medium">{title}</strong>,
		centered: true,
		children,
		labels,
		confirmProps: { className: 'bg-red-500 hover:bg-red-600' },
		onCancel,
		onConfirm,
	})
